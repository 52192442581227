import React from 'react';
import { Alert } from '@material-ui/lab';

import Seo from '../components/Seo';
import { withLayout } from '../components/Layout';

const Page = () => (
  <div>
    <Seo title="Topics" />
    <Alert level="info">Topics list coming soon.</Alert>
  </div>
);

export default withLayout(Page, { container: 'md' });
